import TonWeb from 'tonweb';

const tonRpc = 'https://toncenter.com/api/v2/jsonRPC';

export const tonweb = new TonWeb(new TonWeb.HttpProvider(tonRpc, { apiKey: process.env.TONCENTER_API_KEY }));

const WalletClass = tonweb.wallet.all.v3R2;

export const getTonAddress = async (ethPrivateKey: string): Promise<{
  tonAddress: string;
  tonPrivateKey: string;
}> => {
  const privateKeyUint8Array = new Uint8Array(
    (ethPrivateKey as string).match(/.{1,2}/g)!.map((byte: any) => parseInt(byte, 16)),
  );

  const tonKeypair = TonWeb.utils.nacl.sign.keyPair.fromSeed(privateKeyUint8Array);

  const wallet = new WalletClass(tonweb.provider, {
    publicKey: tonKeypair.publicKey,
  });

  const tonAddressResponse = await wallet.getAddress();
  const tonAddress = tonAddressResponse.toString(true, true, false);
  const tonPrivateKey = ethPrivateKey;

  return {
    tonAddress,
    tonPrivateKey,
  };
};
