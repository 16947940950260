import { Ed25519Keypair } from '@mysten/sui.js/keypairs/ed25519';

export const getSuiAddress = (ethPrivateKey: string): {
  suiAddress: string;
  suiPrivateKey: string;
} => {
  const privateKeyUint8Array = new Uint8Array(
    (ethPrivateKey as string).match(/.{1,2}/g)!.map((byte: any) => parseInt(byte, 16)),
  );

  const suiKeyPair = Ed25519Keypair.fromSecretKey(privateKeyUint8Array);

  const suiAddress = suiKeyPair?.toSuiAddress();
  const suiPrivateKey = suiKeyPair?.getSecretKey();

  return {
    suiAddress,
    suiPrivateKey,
  };
};
