import { z } from 'zod';

export const UploadEarnStatsReqTransformer = z.object({
  payload: z.string(),
});

export const AirdropEarnNftReqTransformer = z.object({
  category: z.string().optional(),
});

export const GetEarnStatsResTransformer = z.object({
  // tgDmCount: z.coerce.number().optional(),
  // tgBotsCount: z.coerce.number().optional(),
  // tgGroupsCount: z.coerce.number().optional(),
  // tgChannelCount: z.coerce.number().optional(),
  earnScore: z.coerce.number().optional(),
  channelsOwned: z.coerce.number().optional(),
  groupsCount: z.coerce.number().optional(),
});

export const TelegramResponse = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  username: z.string().nullable(),
});

export const Leaderboard = z.object({
  telegramDetails: TelegramResponse,
  earnScore: z.coerce.number(),
});

export const GetLeaderboardResTransformer = z.array(Leaderboard);

export const GetLoginStreakResTransformer = z.object({
  loginStreak: z.coerce.number(),
});

export const GetUserRankResTransformer = z.object({
  rank: z.coerce.number(),
});

export const GetInviteAcceptedCountResTransformer = z.object({
  monthlyInviteAcceptedCount: z.coerce.number(),
  totalInviteAcceptedCount: z.coerce.number(),
});
