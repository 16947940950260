import { z } from 'zod';

import { AssetEnum, CampaignEnum, TransactionTypeEnum } from '../enums';

// Create User Wallet //
export const CreateRabbleUserReqTransformer = z.object({
  telegramId: z.string(),
  rabbleEthWallet: z.string(),
  rabbleSolWallet: z.string().optional(),
  rabbleAptosWallet: z.string().optional(),
  rabbleSuiWallet: z.string().optional(),
  rabbleTonWallet: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  username: z.string().optional(),
  referrer: z.string().optional(),
  utm: z.string().optional(),
  campaign: z.nativeEnum(CampaignEnum).optional(),
});

export const CreateRabbleUserResTransformer = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

export const createTransactionReqTransformer = z.object({
  txHash: z.string(),
  type: z.nativeEnum(TransactionTypeEnum),
  fromAddress: z.string(),
  toAddress: z.string(),
  amount: z.number(),
  currency: z.string().optional(),
  network: z.string().optional(),
  gas: z.number(),
});

const TransactionTransformer = z.object({
  txHash: z.string(),
  type: z.nativeEnum(TransactionTypeEnum),
  fromAddress: z.string(),
  toAddress: z.string(),
  amount: z.string(),
  currency: z.string().nullable(),
  network: z.string().nullable(),
  gas: z.string(),
  createdAt: z.coerce.date(),
});

export const ListTransactionResTransformer = z.object({
  total: z.number().optional(),
  count: z.number(),
  page: z.number(),
  size: z.number(),
  lastPage: z.number(),
  results: z.array(TransactionTransformer),
});

// Get User Wallet //
export const GetUserWalletReqTransformer = z.object({
  telegramId: z.string(),
});

export const GetRabbleUserReqTransformer = z.object({
  telegramId: z.string(),
});

export const TelegramUserTransformer = z.object({
  telegramId: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  username: z.string().nullable(),
});

export const GetRabbleUserResTransformer = z.object({
  id: z.string(),
  rabbleEthWallet: z.string().nullable(),
  rabbleSolWallet: z.string().nullable(),
  rabbleAptosWallet: z.string().nullable(),
  rabbleSuiWallet: z.string().nullable(),
  rabbleTonWallet: z.string().nullable().optional(),
  inviteCode: z.string().nullable(),
  source: z.string(),
  email: z.string().nullable(),
  telegramUser: TelegramUserTransformer,
});

// Get Balance //
export const GetBalanceResTransformer = z.record(
  z.nativeEnum(AssetEnum),
  z.number().nullable(),
);

// Save Passcode //
export const SavePasscodeReqTransformer = z.object({
  passcode: z.string(),
});

// Verify Passcode //
export const VerifyPasscodeReqTransformer = z.object({
  passcode: z.string(),
});

// Get Privatekey //
export const GetPrivatekeyReqTransformer = z.object({
  passcode: z.string(),
});
export const GetPrivatekeyResTransformer = z.object({
  privateKey: z.string(),
});

export const GetWalletAddressByTelegramIdResTransformer = z.object({
  rabbleEthWallet: z.string().nullable(),
  rabbleSolWallet: z.string().nullable(),
  rabbleAptosWallet: z.string().nullable(),
  rabbleSuiWallet: z.string().nullable(),
});

export const RequestBetaAccessReqTransformer = z.object({
  email: z.string().email(),
});
