import { z } from 'zod';

export const GetPrivateKeyReqTransformer = z.object({
  passcode: z.string(),
});
export const GetPrivateKeyResTransformer = z.object({
  privateKey: z.string(),
});

export const UpdateWalletRecoveryEmailReqTransformer = z.object({
  email: z.string().email(),
});

export const UpdateWalletRecoveryEmailResTransformer = z.object({
  verificationKey: z.string(),
});

export const VerifyWalletRecoveryEmailReqTransformer = z.object({
  verificationKey: z.string(),
  otp: z.string(),
});

export const RecoverWalletReqTransformer = z.object({
  email: z.string().email(),
});
export const RecoverWalletResTransformer = z.object({
  verificationKey: z.string(),
});

export const RecoverPrivateKeyReqTransformer = z.object({
  verificationKey: z.string(),
  otp: z.string(),
});
export const RecoverPrivateKeyResTransformer = z.object({
  ethereumAddress: z.string(),
  ethereumPrivateKey: z.string(),
  solanaAddress: z.string(),
  solanaPrivateKey: z.string(),
  suiAddress: z.string(),
  suiPrivateKey: z.string(),
});

export const VerifyOtpReqTransformer = z.object({
  verificationKey: z.string(),
  otp: z.string(),
});
