import CryptoJS from 'crypto-js';

const keySize = 256;
const iterations = 100;

export function encryptData(value: string, secret: string) {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.PBKDF2(secret, salt, {
    keySize: keySize / 32,
    iterations,
  });

  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  // Encrypt the session
  const encrypted = CryptoJS.AES.encrypt(value, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}
