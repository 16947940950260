import type { HttpClient } from './http';
import type {
  TCreateChannel, TCreateGroup, TCreateUser, TMessageToUser, TPartOfChannel, TPartOfGroup,
} from './types';
import type { TUpsertChannelNodeWithSubscriberOfRelationReqDto } from './dtos/realm/channel.dto';
import type { TUpsertGroupNodeWithMemberOfRelationReqDto } from './dtos/realm/group.dto';
import type { TUpsertUserNodeWithMessageToRelationReqDto } from './dtos/realm/user.dto';

export class RealmService {
  constructor(private readonly httpClient: HttpClient) {}

  public createUser({
    id, firstName, lastName, username, isBot,
  }: TCreateUser) {
    return this.httpClient.post('/realm/user', {
      id,
      firstName,
      lastName,
      username,
      isBot,
    });
  }

  public createUserAndRelation({
    fromUserId, messageCount, toUserFirstName, toUserId, toUserIsBot, toUserLastName, toUserUsername,
  }: TUpsertUserNodeWithMessageToRelationReqDto) {
    return this.httpClient.patch('/realm/user/upsert-relation', {
      fromUserId, messageCount, toUserFirstName, toUserId, toUserIsBot, toUserLastName, toUserUsername,
    });
  }

  public messageToUser({ fromUser, toUser, messageCount }: TMessageToUser) {
    return this.httpClient.post('/realm/user/relation', {
      fromUser,
      toUser,
      messageCount,
    });
  }

  public createGroup({
    id, groupTitle, totalMembers, username,
  }: TCreateGroup) {
    return this.httpClient.post('/realm/group', {
      id,
      groupTitle,
      totalMembers,
      username,
    });
  }

  public createGroupAndRelation({
    groupId, groupTitle, role, totalMembers, userId,
  }: TUpsertGroupNodeWithMemberOfRelationReqDto) {
    return this.httpClient.patch('/realm/group/upsert-relation', {
      groupId, groupTitle, role, totalMembers, userId,

    });
  }

  public partOfGroup({ userId, groupId, role }: TPartOfGroup) {
    return this.httpClient.post('/realm/group/member', {
      userId,
      groupId,
      role,
    });
  }

  public createChannel({
    id, channelTitle, totalMembers, username,
  }: TCreateChannel) {
    return this.httpClient.post('/realm/channel', {
      id,
      channelTitle,
      totalMembers,
      username,
    });
  }

  public createChannelAndRelation({
    channelTitle, channelId, role, totalMembers, userId,
  }: TUpsertChannelNodeWithSubscriberOfRelationReqDto) {
    return this.httpClient.patch('/realm/channel/upsert-relation', {
      channelTitle, channelId, role, totalMembers, userId,
    });
  }

  public partOfChannel({ userId, channelId, role }: TPartOfChannel) {
    return this.httpClient.post('/realm/channel/subscriber', {
      userId,
      channelId,
      role,
    });
  }
}
