import type { HttpClient } from './http';

import { EarnService } from './earn';
import { RabotService } from './rabot';
import { RealmService } from './realm';
import { UserService } from './user';
import { WalletSettingsService } from './walletSettings';

export class Services {
  public user: UserService;

  public realm: RealmService;

  public earn: EarnService;

  public rabot: RabotService;

  public walletSettings: WalletSettingsService;

  constructor(
    httpClient: HttpClient,
  ) {
    this.user = new UserService(httpClient);
    this.realm = new RealmService(httpClient);
    this.earn = new EarnService(httpClient);
    this.rabot = new RabotService(httpClient);
    this.walletSettings = new WalletSettingsService(httpClient);
  }
}
