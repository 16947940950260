export enum NetworkEnum {
  all = 'all',
  eth = 'eth',
  polygon = 'polygon',
  arbitrum = 'arbitrum',
  solana = 'solana',
  base = 'base',
  aptos = 'aptos',
  sui = 'sui',
  ton = 'ton',
}
