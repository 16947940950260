import {
  Account, Ed25519PrivateKey, PrivateKey, PrivateKeyVariants,
} from '@aptos-labs/ts-sdk';
import { CommonPrivateKeyProvider, getED25519Key } from '@web3auth/base-provider';

export const aptosProvider = new CommonPrivateKeyProvider({
  config: {
    chainConfig: {
      chainNamespace: 'other',
      chainId: '0x1',
      rpcTarget: 'https://rpc.ankr.com/http/aptos/v1',
      displayName: 'Aptos Mainnet',
      blockExplorerUrl: 'https://explorer.aptoslabs.com/',
      ticker: 'APT',
      tickerName: 'Aptos',
    },
  },
});

export const getAptosAddress = async (ethPrivateKey: string): Promise<{
  aptosAddress: string;
  aptosPrivateKey: string;
}> => {
  const formattedAptosPrivateKey = PrivateKey.formatPrivateKey(ethPrivateKey as string, PrivateKeyVariants.Ed25519);
  const ed25519key = getED25519Key(ethPrivateKey as string).sk.toString('hex');

  await aptosProvider.setupProvider(ed25519key);
  const privateKeyInEd25519 = new Ed25519PrivateKey(formattedAptosPrivateKey);
  const aptosAccount = Account.fromPrivateKey({
    privateKey: privateKeyInEd25519,
  });

  const aptosAddress = aptosAccount.accountAddress.toString();
  const aptosPrivateKey = `0x${ethPrivateKey}`;

  return {
    aptosAddress,
    aptosPrivateKey,
  };
};
