import { getEncryptedWallets } from './get-encrypted-wallets';

type TSaveWalletsInBrowserStorageProps = {
  web3AuthWallets: {
    eth: { address: string; privateKey: string };
    sol: { address: string; privateKey: string };
    aptos: { address: string; privateKey: string };
    sui: { address: string; privateKey: string };
    ton: { address: string; privateKey: string };
  };
};

export const saveWalletsInBrowserStorage = ({ web3AuthWallets }: TSaveWalletsInBrowserStorageProps) => {
  const {
    encryptedEthPrivateKey,
    encryptedSolPrivateKey,
    encryptedAptosPrivateKey,
    encryptedSuiPrivateKey,
    encryptedTonPrivateKey,
  } = getEncryptedWallets({
    web3AuthEthWalletPrivateKey: web3AuthWallets.eth.privateKey,
    web3AuthSolWalletPrivateKey: web3AuthWallets.sol.privateKey,
    web3AuthAptosWalletPrivateKey: web3AuthWallets.aptos.privateKey,
    web3AuthSuiWalletPrivateKey: web3AuthWallets.sui.privateKey,
    web3AuthTonWalletPrivateKey: web3AuthWallets.ton.privateKey,
  });

  // Store encrypted private keys in session storage
  sessionStorage.setItem('encryptedEthPrivateKey', encryptedEthPrivateKey);
  sessionStorage.setItem('encryptedSolPrivateKey', encryptedSolPrivateKey);
  sessionStorage.setItem('encryptedAptosPrivateKey', encryptedAptosPrivateKey);
  sessionStorage.setItem('encryptedSuiPrivateKey', encryptedSuiPrivateKey);
  sessionStorage.setItem('encryptedTonPrivateKey', encryptedTonPrivateKey);

  // Store wallet addresses in local storage
  localStorage.setItem('ethWalletAddress', web3AuthWallets.eth.address);
  localStorage.setItem('solWalletAddress', web3AuthWallets.sol.address);
  localStorage.setItem('aptosWalletAddress', web3AuthWallets.aptos.address);
  localStorage.setItem('suiWalletAddress', web3AuthWallets.sui.address);
  localStorage.setItem('tonWalletAddress', web3AuthWallets.ton.address);
};
