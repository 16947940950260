import { z } from 'zod';

import { TransactionTypeEnum, TransactionOwnerEnum } from '../../enums';

export const CreateRabotTransactionReqTransformer = z.object({
  userBotId: z.string().optional().nullable(),
  txHash: z.string(),
  type: z.nativeEnum(TransactionTypeEnum),
  from: z.nativeEnum(TransactionOwnerEnum),
  to: z.nativeEnum(TransactionOwnerEnum),
  fromAddress: z.string(),
  toAddress: z.string(),
  amount: z.number(),
  gas: z.number().optional(),
  currency: z.string().optional(),
  network: z.string().optional(),
});

const UserBotInternalTransactionTransformer = z.object({
  txName: z.string(),
  txHash: z.string(),
  txAmount: z.string(),
  txGas: z.string().nullable(),
  createdAt: z.coerce.date(),
});

const UserBotTransactionTransformer = z.object({
  txName: z.string(),
  txHash: z.string(),
  txAmount: z.string(),
  txGas: z.string().nullable(),
  createdAt: z.coerce.date(),
  internalTxs: z.array(UserBotInternalTransactionTransformer),
});

export const ListUserBotTransactionsResTransformer = z.array(
  UserBotTransactionTransformer,
);
