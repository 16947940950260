import { z } from 'zod';

import { RabotEnum } from '../../enums';

export const CreateUserBotReqTransformer = z.object({
  rabotId: z.string(),
});

export const CreateUserBotResTransformer = z.object({
  id: z.string(),
  smartWalletAddress: z.string().optional(),
});

export const DepositToUserBotReqTransformer = z.object({
  userId: z.string(),
  botId: z.string(),
  botType: z.nativeEnum(RabotEnum),
  amount: z.number(),
});

export const WithdrawFromUserBotReqTransformer = z.object({
  userBotId: z.string().nullable().optional(),
  amountInPercentage: z.number(),
  botType: z.nativeEnum(RabotEnum),
});

export const WithdrawFromUserBotResTransformer = z.object({
  tx: z.string(),
});

export const GetTotalHoldingsResTransformer = z.object({
  totalHoldings: z.number(),
});

export const GetTotalHoldingDistributionResTransformer = z.object({
  balance: z.number(),
});
