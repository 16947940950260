import type { HttpClient } from './http';
import type {
  TRecoverPrivateKeyReqDto, TRecoverPrivateKeyResDto, TRecoverWalletReqDto, TRecoverWalletResDto, TUpdateRecoveryEmailReqDto, TUpdateRecoveryEmailResDto, TVerifyWalletRecoveryEmailReqDto,
} from './dtos';

type TGetPrivateKeyReqDto = {
  passcode: string;
};

export class WalletSettingsService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getPrivateKey(dto: TGetPrivateKeyReqDto) {
    const result = await this.httpClient.patch('/wallets/private-key', dto);
    return result;
  }

  public createPasscode(dto: { passcode: string }): Promise<void> {
    return this.httpClient.post('/wallets/passcode', dto);
  }

  public setWalletRecoveryEmail(dto: TUpdateRecoveryEmailReqDto): Promise<TUpdateRecoveryEmailResDto> {
    return this.httpClient.patch('/rabble-user/recovery/email', dto);
  }

  public verifyWalletRecoveryEmail(dto: TVerifyWalletRecoveryEmailReqDto): Promise<void> {
    return this.httpClient.patch('/rabble-user/recovery/email/verify', dto);
  }

  public recoverEmail(dto: TRecoverWalletReqDto): Promise<TRecoverWalletResDto> {
    return this.httpClient.post('/rabble-user/rabble/recovery/wallet', dto);
  }

  public verifyRecoveryEmail(dto: TRecoverPrivateKeyReqDto): Promise<TRecoverPrivateKeyResDto> {
    return this.httpClient.post('/rabble-user/recovery/wallet/verify', dto);
  }

  public recoverPrivateKey(dto: TRecoverPrivateKeyReqDto): Promise<TRecoverPrivateKeyResDto> {
    return this.httpClient.post('/rabble-user/recover-private-key', dto);
  }
}
