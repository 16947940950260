import { z } from 'zod';
import { CampaignEnum, QuestTaskTypeEnum, QuestTypeEnum } from '../enums';

export const CreateUserQuestReqTransformer = z.object({
  questType: z.nativeEnum(QuestTypeEnum),
});
export const CreateUserQuestResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(QuestTypeEnum),
  completedTasks: z.array(z.nativeEnum(QuestTaskTypeEnum)),
  createdAt: z.date(),
});

export const ListUserQuestsResTransformer = z.array(
  z.object({
    id: z.string(),
    type: z.nativeEnum(QuestTypeEnum),
    completedTasks: z.array(z.nativeEnum(QuestTaskTypeEnum)),
    createdAt: z.date(),
  }),
);

export const GetUserQuestReqTransformer = z.object({
  type: z.nativeEnum(QuestTypeEnum),
});

export const GetUserQuestResTransformer = z.object({
  id: z.string(),
  type: z.nativeEnum(QuestTypeEnum),
  completedTasks: z.array(z.nativeEnum(QuestTaskTypeEnum)),
  createdAt: z.date(),
});

export const InsertUserQuestTaskReqTransformer = z.object({
  questId: z.string(),
  questType: z.nativeEnum(QuestTypeEnum).optional(),
  taskType: z.nativeEnum(QuestTaskTypeEnum),
});

export const GetReferralCountByCampaignTypeReqTransformer = z.object({
  questCampaignType: z.nativeEnum(CampaignEnum),
});

export const GetReferralCountByCampaignTypeResTransformer = z.object({
  referralCount: z.number(),
});
