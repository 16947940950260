import { getED25519Key } from '@web3auth/auth-adapter';
import { SolanaPrivateKeyProvider, SolanaWallet } from '@web3auth/solana-provider';

export const solanaProvider = new SolanaPrivateKeyProvider({
  config: {
    chainConfig: {
      chainNamespace: 'solana',
      chainId: '0x2',
      rpcTarget: 'https://solana-mainnet.g.alchemy.com/v2/23WFY0E9iEFN4dbw55BcKPslLuprxSbd',
      displayName: 'Solana Mainnet',
      blockExplorerUrl: 'https://explorer.solana.com/',
      ticker: 'SOL',
      tickerName: 'Solana',
    },
  },
});

export const getSolanaAddress = async (ethPrivateKey: string): Promise<{
  solAddress: string;
  solPrivateKey: string;
}> => {
  const ed25519key = getED25519Key(ethPrivateKey as string).sk.toString('hex');
  await solanaProvider.setupProvider(ed25519key);

  const solanaWallet = new SolanaWallet(solanaProvider);

  const solanaAddresses = await solanaWallet.requestAccounts();
  const solAddress = solanaAddresses[0];

  const solPrivateKey = await solanaWallet.request({
    method: 'solanaPrivateKey',
  }) as string;

  return {
    solAddress,
    solPrivateKey,
  };
};
