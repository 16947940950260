import * as jose from 'jose';

export async function generateJwtToken(telegramUserId: string) {
  const privateKeyPem = process.env.WEB3_AUTH_PRIVATE_KEY!;
  const kid = process.env.WEB3_AUTH_KID!;

  const privateKey = await jose.importPKCS8(privateKeyPem, 'RS256');

  const token = await new jose.SignJWT({
    iss: 'https://rabblelabs.xyz',
    id: telegramUserId,
    iat: Math.floor(Date.now() / 1000),
  })
    .setProtectedHeader({ alg: 'RS256', kid })
    .sign(privateKey);

  return token;
}
