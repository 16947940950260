import { encryptData } from '../../utils/encrypt';

type TGetEncryptedWalletsProps = {
  web3AuthEthWalletPrivateKey: string;
  web3AuthSolWalletPrivateKey: string;
  web3AuthAptosWalletPrivateKey: string;
  web3AuthSuiWalletPrivateKey: string;
  web3AuthTonWalletPrivateKey: string;
};

type TGetEncryptedWalletsRes = {
  encryptedEthPrivateKey: string;
  encryptedSolPrivateKey: string;
  encryptedAptosPrivateKey: string;
  encryptedSuiPrivateKey: string;
  encryptedTonPrivateKey: string;
};

export const getEncryptedWallets = (
  {
    web3AuthEthWalletPrivateKey,
    web3AuthSolWalletPrivateKey,
    web3AuthAptosWalletPrivateKey,
    web3AuthSuiWalletPrivateKey,
    web3AuthTonWalletPrivateKey,
  }: TGetEncryptedWalletsProps,
): TGetEncryptedWalletsRes => {
  const privateKeyEncrptionSecretKey = process.env.PRIVATE_KEY_ENCRYPTION_SECRET_KEY;

  const encryptedEthPrivateKey = encryptData(web3AuthEthWalletPrivateKey, privateKeyEncrptionSecretKey!);
  const encryptedSolPrivateKey = encryptData(web3AuthSolWalletPrivateKey, privateKeyEncrptionSecretKey!);
  const encryptedAptosPrivateKey = encryptData(web3AuthAptosWalletPrivateKey, privateKeyEncrptionSecretKey!);
  const encryptedSuiPrivateKey = encryptData(web3AuthSuiWalletPrivateKey, privateKeyEncrptionSecretKey!);
  const encryptedTonPrivateKey = encryptData(web3AuthTonWalletPrivateKey, privateKeyEncrptionSecretKey!);

  return {
    encryptedEthPrivateKey,
    encryptedSolPrivateKey,
    encryptedAptosPrivateKey,
    encryptedSuiPrivateKey,
    encryptedTonPrivateKey,
  };
};
