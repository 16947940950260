import type { HttpClient } from './http';
import type {
  TCreateRabotTransactionReqDto,
  TCreateUserBotReqDto, TCreateUserBotResDto, TGetRaboDetailsResDto, TGetUserRabotsOverviewResDto, TListRabotsResDto,
  TListUserBotTransactionsResDto, TWithdrawFromUserBotReqDto,
  TWithdrawFromUserBotResDto,
} from './dtos/rabots';

import {
  CreateUserBotResTransformer,
  GetRabotResTransformer, GetUserRabotsOverviewResTransformer, ListRabotsResTransformer,
  ListUserBotTransactionsResTransformer, WithdrawFromUserBotResTransformer,
} from './transformers';

export class RabotService {
  constructor(private readonly httpClient: HttpClient) {}

  public async getAllRabots() :Promise<TListRabotsResDto> {
    const result = await this.httpClient.get('/rabots');
    return ListRabotsResTransformer.parseAsync(result);
  }

  public async getRabotbyId(
    rabotId:string,
  ):Promise<TGetRaboDetailsResDto> {
    const result = await this.httpClient.get(`/rabots/${rabotId}`);

    return GetRabotResTransformer.parseAsync(result);
  }

  public async getRabotsOverview(
  ):Promise<TGetUserRabotsOverviewResDto> {
    const result = await this.httpClient.get('/rabots/overview');

    return GetUserRabotsOverviewResTransformer.parseAsync(result);
  }

  public async createUserRabotBot(
    payload: TCreateUserBotReqDto,
  ): Promise<TCreateUserBotResDto> {
    const result = await this.httpClient.post('/rabots/user-bot', payload);

    return CreateUserBotResTransformer.parseAsync(result);
  }

  public async createTransaction(
    payload: TCreateRabotTransactionReqDto,
  ): Promise<void> {
    await this.httpClient.post('/rabots/user-bot/transactions', payload);
  }

  public async withdraw(
    payload: TWithdrawFromUserBotReqDto,
  ): Promise<TWithdrawFromUserBotResDto> {
    const result = await this.httpClient.post('/rabots/user-bot/withdraw', payload);

    return WithdrawFromUserBotResTransformer.parseAsync(result);
  }

  public async listTransactions(
    userBotId: string,
  ): Promise<TListUserBotTransactionsResDto> {
    const transactions = await this.httpClient.get(`/rabots/user-bot/transactions/${userBotId}`);

    return ListUserBotTransactionsResTransformer.parseAsync(transactions);
  }
}
