/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable max-len */
import {
  clusterApiUrl,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
} from '@solana/web3.js';
import { z } from 'zod';

import type { HttpClient } from './http';
import type {
  TCreateRabbleUserReqDto,
  TCreateRabbleUserResDto, TCreateTransactionReqDto, TGetRabbleUserResDto, TListTransactionResDto,
  TRequestBetaAccessReqDto
} from './dtos';

import { NetworkEnum } from './enums';
import { SourceEnum } from './enums/source.enum';
import { CreateRabbleUserResTransformer, GetRabbleUserResTransformer, ListTransactionResTransformer } from './transformers';

export const TransferCryptoCurrencyReqTransformer = z.object({
  from: z.string(),
  to: z.string(),
  amount: z.number(),
  asset: z.string(),
  network: z.nativeEnum(NetworkEnum),
});

export const TransferCryptoCurrencyResTransformer = z.object({
  hash: z.string(),
});

export type TTransferCryptoCurrencyReqDto = z.infer<
  typeof TransferCryptoCurrencyReqTransformer
>;

export type TTransferCryptoCurrencyResDto = z.infer<
  typeof TransferCryptoCurrencyResTransformer
>;

export class UserService {
  constructor(private readonly httpClient: HttpClient) {}

  // -------------------------------PUBLIC--------------------------------- //

  public async createUser(
    {
      telegramId,
      firstName,
      lastName,
      username,
      rabbleEthWallet,
      rabbleSolWallet,
      rabbleAptosWallet,
      rabbleSuiWallet,
      rabbleTonWallet,
      referrer,
      utm,
      campaign
    }: TCreateRabbleUserReqDto
  ) : Promise<TCreateRabbleUserResDto> {
    const payload: any = {
      telegramId,
      source: 'WEB',
      rabbleEthWallet,
      rabbleSolWallet,
      rabbleAptosWallet,
      rabbleSuiWallet,
      rabbleTonWallet,
      firstName,
      lastName,
      username,
      campaign
    };

    // Add referrer only if it's defined
    if (referrer) {
      payload.referrer = referrer;
    }

    // Add utm only if it's defined
    if (utm) {
      payload.utm = utm;
    }

    const result = await this.httpClient.post('/rabble-user', payload);
    return CreateRabbleUserResTransformer.parseAsync(result);
  }

  public async createTransaction(dto: TCreateTransactionReqDto): Promise<void> {
    await this.httpClient.post('/transactions', dto);
  }

  // public async listTransaction(): Promise<TListTransactionResDto> {
  //   return await this.httpClient.get('/transactions');
  // }

  public async getMe() :Promise<TGetRabbleUserResDto> {
    const result = await this.httpClient.get('/rabble-user/me');
    return GetRabbleUserResTransformer.parseAsync(result);
  }

  public async listTransaction(page = 1, size = 5) :Promise<TListTransactionResDto> {
    const result = await this.httpClient.get(`/transactions?page=${page}&size=${size}`);
    return ListTransactionResTransformer.parseAsync(result);
  }

  public async getAllNetworksWalletBalance(
    ethWalletAddress:string,
    solanaWalletAddress:string,
    aptosWalletAddress:string,
    suiWalletAddress: string,
    tonWalletAddress: string,
  ):Promise<any> {
    const result = await this.httpClient.get(`/rabble-user/all-balances-by-address?ethWalletAddress=${ethWalletAddress}&solanaWalletAddress=${solanaWalletAddress}&aptosWalletAddress=${aptosWalletAddress}&suiWalletAddress=${suiWalletAddress}&tonWalletAddress=${tonWalletAddress}`);

    return result;
  }

  public getWalletAddressByTelegramId(telegramId: string): Promise<{
    rabbleEthWallet: string;
    rabbleSolWallet: string;
    rabbleAptosWallet: string;
    rabbleSuiWallet?: string;
    rabbleTonWallet?: string;
  }> {
    return this.httpClient.get(`/rabble-user/wallet?telegramId=${telegramId}`);
  }

  public uploadUserDialogs(dto: {
    telegramId: string;
    file: any;
  }): undefined | Promise<any> {
    const formData = new FormData();
    formData.append('dialogs', dto.file);

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      return;
    }

    this.httpClient.postDialogs('/uploads/dialogs', formData, {
      'x-telegram-id': dto.telegramId,
      'x-source': SourceEnum.WEB,
    },);
  }

  private async getSolanaWalletBalance(solanaWalletAddress: string): Promise<any> {
    const solanaRpc = process.env.SOLANA_RPC_URL;

    const connection = new Connection(
      // solanaRpc,
      'https://solana-mainnet.g.alchemy.com/v2/23WFY0E9iEFN4dbw55BcKPslLuprxSbd',
      'confirmed'
    );

    const wallet = new PublicKey(solanaWalletAddress);

    console.log({ wallet });

    const solanaBalance = await connection.getBalance(wallet);
    console.log({ solanaBalance });

    const solanaWalletBalance = solanaBalance / LAMPORTS_PER_SOL;

    console.log({ solanaWalletBalance });

    return {
      blockchain: 'solana',
      tokenName: 'SOL',
      tokenSymbol: 'SOL',
      tokenDecimals: 9,
      tokenType: 'SOL',
      contractAddress: '0x0000000000000000000000000000000000000000',
      holderAddress: '',
      balance: solanaWalletBalance.toString(),
      balanceRawInteger: solanaWalletBalance.toString(),
      balanceUsd: null,
      tokenPrice: null,
      thumbnail: null
    };
  }

  public async requestBetaAccess(dto: TRequestBetaAccessReqDto): Promise<void> {
    await this.httpClient.post('/rabble-user/request-beta', dto);
  }
}
